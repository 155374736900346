/* eslint-disable max-len */
import React from 'react';
import xw from 'twin.macro';

const styles = {
  heading: xw`text-3xl leading-9 tracking-tight font-extrabold sm:text-4xl sm:leading-10 mb-2`,
  subHeading: xw`mt-2 text-xl leading-7 font-semibold`,
  tag: xw`inline-flex items-center px-3 py-2 rounded-sm text-sm font-medium leading-5 bg-gray-200 text-black dark:text-white dark:bg-gray-700 -ml-1`,
};

function Component() {
  return (
    <div className="relative max-w-lg px-4 pt-6 pb-20 mx-auto sm:px-6 lg:pt-12 lg:pb-28 lg:px-8 lg:max-w-7xl">
      <h1 className="text--color-heading-1" css={[styles.heading]}>
        My Work
      </h1>
      <ul className="grid mt-6 border-t-2 lg:grid-cols-2">
        <li className="p-8 border-b-2 lg:border-r-2">
          <div className="mb-4">
            <span css={[styles.tag]}>Engineering Projects</span>
          </div>
          <ul className="mt-3 text-base leading-6 text-gray-900 list-disc dark:text-white">
            <li className="mb-4">
              Airport Construction, New Denver International Airport, Denver
              Colorado USA, 1991- 1994
            </li>
            <li className="mb-4">
              Airport Construction, New KL International Airport, Sepang
              Malaysia, 1994- 2002
            </li>
            <li className="mb-4">KL Monorail Project, Malaysia, 2002-2006</li>
          </ul>
        </li>

        <li className="p-8 border-b-2">
          <div className="mb-4">
            <span css={[styles.tag]}>Humanitarian Relief Work</span>
          </div>
          <ul className="mt-3 text-base leading-6 text-gray-900 list-disc dark:text-white">
            <li className="mb-4">
              UN IOM Indonesia, Technical advisor - construction manager
              (2006-2009)
            </li>
            <li className="mb-4">
              UN IOM Afghanistan, Technical Advisor - QAQC Manager (2009-2013)
            </li>
            <li className="mb-4">
              UN IOM Bangladesh (COX BAZAR), Short Term Assignement (2018)
            </li>
            <li className="mb-4">
              UN IOM - UNDP Nepal , Short Term Assigement (2012-2013)
            </li>
            <li className="mb-4">
              UN IOM Iraq, Shelter & Settlement Engineer - Head Technical
              Engineering Department (2014-2020)
            </li>
            <li className="mb-4">
              UN IOM Afghanistan, Technical Consultant (2021 - Current)
            </li>
          </ul>
        </li>

        <li className="p-8 border-b-2 lg:border-r-2">
          <div className="mb-4">
            <span css={[styles.tag]}>Training and Capacity Building</span>
          </div>
          <ul className="mt-3 text-base leading-6 text-gray-900 list-disc dark:text-white">
            <li className="mb-4">
              Project Management training courses, KLIACS research And Training
              Center, Sepang Malaysia (2005-2006)
            </li>
            <li className="mb-4">
              Project Management training courses, UN IOM Aceh Indonesia 2007
            </li>
            <li className="mb-4">
              Shelter rehabilitation and WASH On the job capacity building
              training, UN IOM Aceh Indonesia 2007
            </li>
            <li className="mb-4">
              Project Management training courses, UN IOM Tanjung Pinang
              Indonesia 2008
            </li>
            <li className="mb-4">
              Shelter rehabilitation and upgrading on the job training program
              UN IOM Iraq (2014-2018)
            </li>
            <li className="mb-4">
              CCCM camp construction, camp care, and maintenance on the job
              training program, UN IOM Iraq (2014-2018)
            </li>
            <li className="mb-4">
              Standard procedures and guidelines in technical engineering
              construction documents for UN community development projects, UN
              IOM Iraq (2018-2020)
            </li>
          </ul>
        </li>
      </ul>
    </div>
  );
}

export const Main = Component;
